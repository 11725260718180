<template>
  <div :class="['list-extend-wrap']">
    <template v-if="dataType === 'array'">
      <div class="list-array">
        <pre>{{ showData.join('\n') }}</pre>
        <div v-if="listLen > 5 && !showAll" class="list-extend-item">...</div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in showData"
        :key="`${item.slice(0, 1)}${Math.random()}`"
        class="list-extend-item"
      >
        <span class="label">{{ getArguments(index) }}</span>
        <span class="value">{{ item }}</span>
      </div>
      <div v-if="listLen > 5 && !showAll" class="list-extend-item">...</div>
    </template>
    <div v-if="listLen > 5" class="show-all" @click="showAll = !showAll">
      {{ showAll ? $t('tracked.close') : $t('tracked.showMore') }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from 'vue'
// event 类型中 错误栈及多条代码块的扩展组件
import eventDesc from '@/assets/markdown/event_description.json'
import { isZh } from 'app/i18n'

const props = defineProps({
  list: {
    type: [Array, Object]
  }
})

const copyData = ref(null)
const showAll = ref(false)

const argumentsList = computed(() => {
  return eventDesc.argument
})
const listLen = computed(() => {
  return dataType.value === 'array' ? props.list.length : Object.keys(props.list).length
})
const showData = computed(() => {
  if (dataType.value === 'array') {
    return showAll.value ? props.list : props.list.slice(0, 5)
  } else {
    if (showAll.value) {
      return props.list
    }
    const keys = Object.keys(props.list).slice(0, 5)
    const result = {}
    for (const i of keys) {
      result[i] = props.list[i]
    }
    return result
  }
})
const dataType = computed(() => {
  return Object.prototype.toString
    .call(props.list)
    .slice(8, -1)
    .toLowerCase()
})

// const getFlagsType = (obj) => {
//   return Object.prototype.toString
//     .call(obj)
//     .slice(8, -1)
//     .toLowerCase()
// }
const getArguments = (index) => {
  return isZh() ? ((argumentsList.value[index] && argumentsList.value[index].zhcn) || index) : ((argumentsList.value[index] && argumentsList.value[index].enus) || index)
}

onMounted(() => {
  copyData.value = props.list
})
</script>

<style lang="scss" scoped>
.list-extend-wrap {
  .list-array {
    font-family: monospace, monospace;
    background: var(--color-bg-3);
    padding: 10px;
  }
  .list-extend-item {
    line-height: 20px;
    .label {
      display: inline-block;
      width: 110px;
    }
    .value {
    }
  }
  .show-all {
    color:var(--color-primary-8);
    cursor: pointer;
  }
}
</style>
